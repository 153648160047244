import LogoSite from "../../images/logo_site.png";
import Building from "../../images/building_sample.png";
import HeaderDetail from "../../images/header_detail.png";

import Form from "../Form";

import "./styles.css";

function Header() {
   return (
      <div id="form" className="header-site">
         <img className="header-detail" src={HeaderDetail} alt="" />
         <div className="header-content">
            <img
               className="header-building"
               src={Building}
               alt="imagem representativa da construção"
            />
            <div className="header-texts-section">
               <div className="header-merchan">
                  <div className="header-title">
                     <img
                        className="header-logo"
                        src={LogoSite}
                        alt="logo do site"
                     />
                     <div className="header-merchan-title txt-accent-color uppercase txt-thin txt-big">
                        <div className="merchan-title title-top">
                           <p>Será um</p>
                        </div>
                        <div className="merchan-title title-mid">
                           <p>prazer estar</p>
                        </div>
                        <div className="merchan-title title-bot">
                           <p>em casa</p>
                        </div>
                     </div>
                  </div>
                  <Form />
               </div>
               <div className="header-merchan-paragraph txt-accent-color txt-thin txt-normal">
                  <p className="txt-bold">
                     As vantagens do Morata Clube Residence vão te conquistar!
                  </p>
                  <p>
                     Apartamentos de 2 quartos com suíte e varanda, localização
                     privilegiada com acesso fácil aos principais pontos da
                     cidade, com metrô na porta e uma estrutura de lazer
                     completa. Venha viver o prazer de estar em casa no Morata
                     Clube.
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Header;
