import "./styles.css";
import Maps from "../../images/maps.png";

function MapLocalization() {
   return (
      <div className="maps-site">
         <div className="maps-information">
            <h2 className="desktop-size maps-information-title txt-main-color txt-bigger">
               VOCÊ PERTINHO DE <br /> TUDO O QUE PRECISA
            </h2>
            <h2 className="maps-information-subtitle txt-accent-color txt-small txt-center">
               EXCELENTE LOCALIZAÇÃO EM FRENTE AO METRÔ
            </h2>
            <div className="maps-information-reference txt-normal ">
               <p className="txt-main-color">PRÓXIMO DE:</p>
               <p className="txt-main-color txt-bold">
                  SUPERMERCADOS, SHOPPING, ESCOLAS, FACULDADE, FARMÁCIAS
               </p>
            </div>
         </div>
         <a href="https://goo.gl/maps/L82hYX8prGa6giXPA" target="__blank">
            <img src={Maps} alt="Localização do Prédio" />
         </a>
      </div>
   );
}

export default MapLocalization;
