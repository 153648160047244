import "./styles.css";
import Views from "../../images/views.png";
import leisureIcons from "../../images/leisureIcons.png";

function Leisure() {
   return (
      <div className="banner-container">
         <div className="banner-content">
            <h1 className="banner-title txt-white txt-bigger">
               MAIS LAZER,
               <br /> SEM SAIR DE CASA
            </h1>
            <img
               className="banner-leisure"
               src={leisureIcons}
               alt="Itens de Lazer"
            />
         </div>
         <img
            className="banner-views"
            src={Views}
            alt="Lugares de lazer do empreendimento"
         />
      </div>
   );
}

export default Leisure;
