import "./styles.css";
import Carousel from "../Carousel";

import image1 from "../../images/morata3.jpg";
import image2 from "../../images/morata14.jpg";
import image3 from "../../images/morata5.jpg";
import image4 from "../../images/morata6.jpg";
import image5 from "../../images/morata7.jpg";
import image6 from "../../images/morata8.jpg";
import image7 from "../../images/morata9.jpg";
import image8 from "../../images/morata10.jpg";
import image9 from "../../images/morata11.jpg";
import image10 from "../../images/morata12.jpg";
import image11 from "../../images/morata13.jpg";
import image12 from "../../images/morata4.jpg";
import image13 from "../../images/morata15.jpg";
import image14 from "../../images/morata16.jpg";
import image15 from "../../images/morata17.jpg";
import image17 from "../../images/morata20.jpg";
import image18 from "../../images/morata23.jpg";
import image19 from "../../images/morata24.jpg";
import image20 from "../../images/morata29.jpg";

function CarouselPhotos() {
   return (
      <div className="banner-carousel-container">
         <Carousel screenAspect={"desktop"} show={3}>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image9}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image1}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image2}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image3}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image4}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image5}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image6}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image7}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image8}
                  alt="Imagem da Sala de Jantar"
               />
            </div>

            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image10}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image11}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image12}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image13}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image14}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image15}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image18}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image17}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image19}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image20}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
         </Carousel>
         <Carousel screenAspect={"mobile"} show={1}>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image9}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image1}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image2}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image3}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image4}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image5}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image6}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image7}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image8}
                  alt="Imagem da Sala de Jantar"
               />
            </div>

            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image10}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image11}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image12}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image13}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image14}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image15}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image18}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image17}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image19}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image20}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
         </Carousel>
      </div>
   );
}

export default CarouselPhotos;
