import "./styles.css";
import LogoMaxDerby from "../../images/morataLogo.png";
import LogoMaxPlural from "../../images/LogoMaxPlural.png";
import PhoneNumberIcon from "../../images/logoItacon.png";

import CarouselPhotos from "../CarouselPhotos";
import Form from "../Form";

function Footer() {
   return (
      <div className="footer-site">
         <CarouselPhotos />

         <div className="footer-group">
            <div className="footer-logo-info">
               <div className="footer-logos">
                  <img
                     className="footer-max-derby-logo"
                     src={LogoMaxDerby}
                     alt="Logo da Max Derby"
                  />
                  <img src={LogoMaxPlural} alt="Logo da Max Plural" />
                  <img src={PhoneNumberIcon} alt="Phone Number icon" />
               </div>
               <div className="footer-contact txt-big txt-bold">
                  {/* <a
                     href="https://www.maxplural.com.br"
                     target="_blank"
                     rel="noreferrer"
                     className="txt-accent-color"
                  >
                     www.maxplural.com.br
                  </a> */}
                  {/* <a href="tel:40001581">
                  <span className="txt-accent-color">| 4000-1581</span>
               </a> */}
               </div>
            </div>
            <div className="footer-form">
               <Form />
            </div>
         </div>
      </div>
   );
}

export default Footer;
