import "./styles.css";
import SiteDetail from "../../images/site-detail.png";
import SiteDetail2 from "../../images/site-detail2.png";
function ApartmentsDetails() {
   return (
      <div className="apartment-container txt-orange">
         <img
            className="img-apartment-detail detail-left"
            src={SiteDetail2}
            alt=""
         />
         <img
            className="img-apartment-detail detail-right"
            src={SiteDetail}
            alt=""
         />
         <div className="apartment-info">
            <span className="apartment-title txt-accent-color txt-big">
               ESCOLHER O MORATA <br />
               CLUBE COMO SEU <br />
               FUTURO LAR É TER <br />
               TUDO O QUE VOCÊ <br />
               PROCURA EM UM
               <br />
               EMPREENDIMENTO.
            </span>
            <div className="apartment-details">
               <span className="apartment-bubble-info txt-normal  txt-accent-color">
                  2 QUARTOS C/ SUÍTE <br />E VARANDA
               </span>
               <span className="apartment-bubble-info txt-normal  txt-accent-color">
                  COZINHA
               </span>
               <span className="apartment-bubble-info txt-normal  txt-accent-color">
                  ÁREA DE SERVIÇO
               </span>
               <span className="apartment-bubble-info  txt-normal txt-accent-color">
                  WC SOCIAL
               </span>
            </div>
         </div>
      </div>
   );
}

export default ApartmentsDetails;
