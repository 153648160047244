import Header from "../../components/Header";
import MapLocalization from "../../components/MapLocalization";
import ApartmentsDetails from "../../components/ApartmentsDetails";
import Leisure from "../../components/Leisure";
import Footer from "../../components/Footer";
import FloatingPanel from "../../components/FloatingPanel";
import "./styles.css";

export default function Home() {
   return (
      <div className="homepage">
         <FloatingPanel />
         <Header />
         <MapLocalization />
         <ApartmentsDetails />
         <Leisure />
         <Footer />
      </div>
   );
}
